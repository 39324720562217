<template>
  <b-row>
    <b-col>
      <b-row v-if="table.display && events.length > 0">
        <b-col>
          <b-table
            ref="eventsTable"
            striped
            hover
            responsive
            :items="events"
            :fields="table.fields"
            :sort-by.sync="table.sortBy"
            :sort-desc.sync="table.sortDesc"
            :primary-key="events.nwpId"
          >
            <template #cell(id)="row">
              <b-button size="sm" @click="displayEventDetails(row.item)">
                {{ $t('veranstalter_seite.meine_wettkämpfe.label_details') }}
              </b-button>
            </template>

            <template #cell(nwpStatus)="row">
              <span class="text-warning" v-if="row.value === 'Lov.Sat.NWPStatus.Submitted'">{{ $t(row.value) }}</span>
              <span class="text-danger" v-if="row.value === 'Lov.Sat.NWPStatus.Declined'">{{ $t(row.value) }}</span>
              <span class="text-success" v-if="row.value === 'Lov.Sat.NWPStatus.Authorized'">{{ $t(row.value) }}</span>
              <span class="text-success" v-if="row.value === 'Lov.Sat.NWPStatus.Executed'">{{ $t(row.value) }}</span>
              <span class="text-danger" v-if="row.value === 'Lov.Sat.NWPStatus.Cancelled'">{{ $t(row.value) }}</span>
              <span class="text-success" v-if="row.value === 'Lov.Sat.NWPStatus.Finished'">{{ $t(row.value) }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row v-if="eventDetail.display && eventDetail.selectedEvent" class="mt-3">
        <b-col cols="12" class="mb-3">
          <b-row class="bg-mellow p-3 mb-3">
            <b-col>
              <h1 class="mb-0">{{ eventDetail.selectedEvent.nwpName }}</h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col id="accordion-wrapper">

              <b-row v-b-toggle.accordion-veranstaltungs-kategorie style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_veranstaltungs_kategorie') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-veranstaltungs-kategorie" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_wettkampftyp') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.nwpType || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_wettkampfkategorie') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.nwpCategory || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_eventkategorie') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.nwpEligible || '-' }}
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-veranstalter style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_veranstalter_verein') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-veranstalter" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_veranstalter_verein_schule_organisation') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.schoolOrOrganisation || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_wettkampfname') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.nwpName || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_homologation') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.nwpHomologation) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_sportanlage_stadion') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.sportField || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_veranstaltungsadresse') }}:
                  </b-col>
                  <b-col>
                    <span v-if="eventDetail.selectedEvent.nwpAddress" class="d-block">{{ eventDetail.selectedEvent.nwpAddress }}</span>
                    <span v-if="eventDetail.selectedEvent.nwpZipCode" class="d-inline-block">{{ eventDetail.selectedEvent.nwpZipCode }}</span>
                    <span v-if="eventDetail.selectedEvent.venue" class="d-inline-block">, {{ eventDetail.selectedEvent.venue }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_kanton') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.nwpCanton || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_webseite') }}:
                  </b-col>
                  <b-col>
                    <b-link v-if="eventDetail.selectedEvent.webpage" :href="eventDetail.selectedEvent.webpage" target="_blank">{{ eventDetail.selectedEvent.webpage || '-' }}</b-link>
                    <span v-else>{{ eventDetail.selectedEvent.webpage || '-' }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_austragungsdatum_zeit') }}:
                  </b-col>
                  <b-col>
                    {{ formatDate(eventDetail.selectedEvent.startDate) || '-' }} / {{ formatTime(eventDetail.selectedEvent.startTime) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_erwartete_teilnehmerzahl') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.expectedParticipantsCount || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_startgeld') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.hasEntryFee) || '-' }}<span v-if="eventDetail.selectedEvent.entryFee" class="ml-1">({{ eventDetail.selectedEvent.entryFee }} CHF)</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_anmeldeschluss') }}:
                  </b-col>
                  <b-col>
                    {{ formatDate(eventDetail.selectedEvent.deadline) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_nachmeldungen_auf_platz_möglich') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.registrationOnSitePossible ? 'Ja' : 'Nein' || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_zeitmessung') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.timeMeasure) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_benutzte_auswertungssoftware') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.usedEvaluationSoftware) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_infomail_bei_teilnehmeranmeldung') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.infomailOnNewRegistrations) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_jüngste_kategorie') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.youngestCategory) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_teilnahmeeinschränkung') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.participationLimitation) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_qualifikation_für_nächste_stufe') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.qualificationForNextStageLic) || '-' }}
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-kontakt style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_kontakt') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-kontakt" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_name') }}:
                  </b-col>
                  <b-col>
                    <span>{{ eventDetail.selectedEvent.contactFirstName }}</span>
                    <span class="ml-1">{{ eventDetail.selectedEvent.contactName }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_adresse') }}:
                  </b-col>
                  <b-col>
                    <span v-if="eventDetail.selectedEvent.contactAddress" class="d-block">{{ eventDetail.selectedEvent.contactAddress }}</span>
                    <span v-if="eventDetail.selectedEvent.contactAddressLine2" class="d-block">{{ eventDetail.selectedEvent.contactAddressLine2 }}</span>
                    <span v-if="eventDetail.selectedEvent.contactZipCode" class="d-inline-block">{{ eventDetail.selectedEvent.contactZipCode }}</span>
                    <span v-if="eventDetail.selectedEvent.contactCity" class="d-inline-block">, {{ eventDetail.selectedEvent.contactCity }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_kanton') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.contactCanton) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_tel_tagsüber_mobile') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.contactMobile || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_telefon_privat') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.contactPhone || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_email') }}:
                  </b-col>
                  <b-col>
                    <b-link v-if="eventDetail.selectedEvent.email" :href="`mailto:${eventDetail.selectedEvent.email}`">{{ eventDetail.selectedEvent.email }}</b-link>
                    <span v-else>{{ eventDetail.selectedEvent.email || '-' }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_sprache') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.correspondenceLanguage) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_verein_organisation') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.contactOrganisation || '-' }}
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-if="eventDetail.displayBankAccount" v-b-toggle.accordion-bankinformation style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_bankinformation') }}</h2>
                </b-col>
              </b-row>

              <b-collapse v-if="eventDetail.displayBankAccount" id="accordion-bankinformation" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_iban') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.iban || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_name_der_bank') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.instituteName || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_kontoinhaber') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.instituteOwner || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_adresse_des_kontoinhabers') }}:
                  </b-col>
                  <b-col v-if="eventDetail.selectedEvent.instituteOwnerAddress == '' && eventDetail.selectedEvent.instituteOwnerZip == '' && eventDetail.selectedEvent.instituteOwnerLocation == ''">
                    -
                  </b-col>
                  <b-col v-else>
                    <span v-if="eventDetail.selectedEvent.instituteOwnerAddress" class="d-block">{{ eventDetail.selectedEvent.instituteOwnerAddress }}</span>
                    <span v-if="eventDetail.selectedEvent.instituteOwnerZip" class="d-inline-block">{{ eventDetail.selectedEvent.instituteOwnerZip }}</span>
                    <span v-if="eventDetail.selectedEvent.instituteOwnerLocation" class="d-inline-block">, {{ eventDetail.selectedEvent.instituteOwnerLocation }}</span>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row v-if="eventDetail.displayBankAccount">
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-material style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">Material</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-material" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row v-if="eventDetail.material.length > 0">
                  <b-col>
                    <b-row v-for="material in eventDetail.material" :key="material.id" class="mb-3">
                      <b-col cols="2">
                        <span>
                          {{ $t(material.materialTitleLic) }}
                        </span>
                      </b-col>
                      <b-col cols="2" class="text-center">
                        <b-img :src="material.image.src" fluid :alt="`Material - ${material.materialTitleLic}`"></b-img>
                      </b-col>
                      <b-col cols="3">
                        <span v-html="material[`description${$i18n.locale.toUpperCase()}`]"></span>
                      </b-col>
                      <b-col cols="2">
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Startnumber'">
                          <b-col>
                            <!-- Feld: Startnummern (Wird mit Wert aus "expectedParticipantsCount" vorausgefüllt) -->
                            <b-form-input
                              id="input-startnumber"
                              v-model="eventDetail.selectedEvent.startnumber"
                              aria-describedby="feedback-startnumber"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Diploma'">
                          <b-col cols="1">
                            <label for="input-diplomaDE">DE</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Diplome -->
                            <b-form-input
                              id="input-diplomaDE"
                              v-model="eventDetail.selectedEvent.diplomaDE"
                              aria-describedby="feedback-diplomaDE"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Diploma'">
                          <b-col cols="1">
                            <label for="input-diplomaFR">FR</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Diplome -->
                            <b-form-input
                              id="input-diplomaFR"
                              v-model="eventDetail.selectedEvent.diplomaFR"
                              aria-describedby="feedback-diplomaFR"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Diploma'">
                          <b-col cols="1">
                            <label for="input-diplomaIT">IT</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Diplome -->
                            <b-form-input
                              id="input-diplomaIT"
                              v-model="eventDetail.selectedEvent.diplomaIT"
                              aria-describedby="feedback-diplomaIT"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'">
                          <b-col cols="1">
                            <label for="input-posterDE">DE</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Plakat -->
                            <b-form-input
                              id="input-posterDE"
                              v-model="eventDetail.selectedEvent.posterDE"
                              aria-describedby="feedback-posterDE"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'">
                          <b-col cols="1">
                            <label for="input-posterFR">FR</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Plakat -->
                            <b-form-input
                              id="input-posterFR"
                              v-model="eventDetail.selectedEvent.posterFR"
                              aria-describedby="feedback-posterFR"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'">
                          <b-col cols="1">
                            <label for="input-posterIT">IT</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Plakat -->
                            <b-form-input
                              id="input-posterIT"
                              v-model="eventDetail.selectedEvent.posterIT"
                              aria-describedby="feedback-posterIT"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Poster'" class="mt-3">
                          <b-col>
                            <b-form-checkbox
                              id="input-digitalPoster"
                              v-model="eventDetail.selectedEvent.digitalPoster"
                              :value="true"
                              :unchecked-value="false"
                              class="mb-3"
                              disabled
                            >
                              {{ $t('event_registrierung.registrierung.label_digitale_version_des_plakates') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'">
                          <b-col cols="1">
                            <label for="input-flyerDE">DE</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Flyer -->
                            <b-form-input
                              id="input-flyerDE"
                              v-model="eventDetail.selectedEvent.flyerDE"
                              aria-describedby="feedback-flyerDE"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'">
                          <b-col cols="1">
                            <label for="input-flyerFR">FR</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Flyer -->
                            <b-form-input
                              id="input-flyerFR"
                              v-model="eventDetail.selectedEvent.flyerFR"
                              aria-describedby="feedback-flyerFR"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'">
                          <b-col cols="1">
                            <label for="input-flyerIT">IT</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Flyer -->
                            <b-form-input
                              id="input-flyerIT"
                              v-model="eventDetail.selectedEvent.flyerIT"
                              aria-describedby="feedback-flyerIT"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Flyer'" class="mt-3">
                          <b-col>
                            <b-form-checkbox
                              id="input-digitalFlyer"
                              v-model="eventDetail.selectedEvent.digitalFlyer"
                              :value="true"
                              :unchecked-value="false"
                              class="mb-3"
                              disabled
                            >
                              {{ $t('event_registrierung.registrierung.label_digitale_version_des_flyers') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Medal' && (eventDetail.selectedEvent.nwpCategoryKey === 'Lov.Sat.NWPCategory.SprintCantonFinal' || eventDetail.selectedEvent.nwpCategoryKey === 'Lov.Sat.NWPCategory.SprintSwissFinal')">
                          <b-col>
                            <!-- Feld: Medaillen (Wird nur angezeigt bei Kantonalfinal oder Schweizerfinal) -->
                            <b-form-input
                              id="input-medal"
                              v-model="eventDetail.selectedEvent.medal"
                              aria-describedby="feedback-medal"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Medal' && eventDetail.selectedEvent.nwpCategoryKey === 'Lov.Sat.NWPCategory.SprintLocalContest'">
                          <b-col>
                            <!-- Feld: Medaillen Interesse (Wird nur angezeigt bei lokaler Ausscheidung) -->
                            <b-form-input
                              id="input-medalsInterest"
                              v-model="eventDetail.selectedEvent.medalsInterestLic"
                              aria-describedby="feedback-medalsInterest"
                              type="text"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtS">S</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse S -->
                            <b-form-input
                              id="input-helperShirtS"
                              v-model="eventDetail.selectedEvent.helperShirtS"
                              aria-describedby="feedback-helperShirtS"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtM">M</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse M -->
                            <b-form-input
                              id="input-helperShirtM"
                              v-model="eventDetail.selectedEvent.helperShirtM"
                              aria-describedby="feedback-helperShirtM"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtL">L</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse L -->
                            <b-form-input
                              id="input-helperShirtL"
                              v-model="eventDetail.selectedEvent.helperShirtL"
                              aria-describedby="feedback-helperShirtL"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperShirt'">
                          <b-col cols="1">
                            <label for="input-helperShirtXL">XL</label>
                          </b-col>
                          <b-col>
                            <!-- Feld: Anzahl Helfershirts Grösse XL -->
                            <b-form-input
                              id="input-helperShirtXL"
                              v-model="eventDetail.selectedEvent.helperShirtXL"
                              aria-describedby="feedback-helperShirtXL"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.HelperCap'">
                          <b-col>
                            <!-- Feld: Anzahl Helfercaps -->
                            <b-form-input
                              id="input-helperCap"
                              v-model="eventDetail.selectedEvent.helperCap"
                              aria-describedby="feedback-helperCap"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.WinnerPrize'">
                          <b-col>
                            <!-- Feld: Anzahl Siegerpreise -->
                            <b-form-input
                              id="input-winnerPrize"
                              v-model="eventDetail.selectedEvent.winnerPrize"
                              aria-describedby="feedback-winnerPrize"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.PodiumPrize'">
                          <b-col>
                            <!-- Feld: Anzahl Podestpreise -->
                            <b-form-input
                              id="input-podiumPrize"
                              v-model="eventDetail.selectedEvent.podiumPrize"
                              aria-describedby="feedback-podiumPrize"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.ParticipantGift'">
                          <b-col>
                            <!-- Feld: Anzahl Teilnehmergeschenke -->
                            <b-form-input
                              id="input-participantGift"
                              v-model="eventDetail.selectedEvent.participantGift"
                              aria-describedby="feedback-participantGift"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.StartingTent'">
                          <b-col>
                            <!-- Feld: Anzahl Startzelt -->
                            <b-form-input
                              id="input-startingTent"
                              v-model="eventDetail.selectedEvent.startingTent"
                              aria-describedby="feedback-startingTent"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Tent'">
                          <b-col>
                            <!-- Feld: Anzahl Zelt -->
                            <b-form-input
                              id="input-tent"
                              v-model="eventDetail.selectedEvent.tent"
                              aria-describedby="feedback-tent"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Banner'">
                          <b-col>
                            <!-- Feld: Anzahl Banner -->
                            <b-form-input
                              id="input-banner"
                              v-model="eventDetail.selectedEvent.banner"
                              aria-describedby="feedback-banner"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.PopOutFlag'">
                          <b-col>
                            <!-- Feld: Anzahl Pop-Out-Flags -->
                            <b-form-input
                              id="input-popOutFlag"
                              v-model="eventDetail.selectedEvent.popOutFlag"
                              aria-describedby="feedback-popOutFlag"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.BeachFlag'">
                          <b-col>
                            <!-- Feld: Anzahl Beach Flags -->
                            <b-form-input
                              id="input-beachFlag"
                              v-model="eventDetail.selectedEvent.beachFlag"
                              aria-describedby="feedback-beachFlag"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Archway'">
                          <b-col>
                            <!-- Feld: Anzahl Torbögen -->
                            <b-form-input
                              id="input-archway"
                              v-model="eventDetail.selectedEvent.archway"
                              aria-describedby="feedback-archway"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Parasol'">
                          <b-col>
                            <!-- Feld: Anzahl Beach Sonnenschirme -->
                            <b-form-input
                              id="input-parasol"
                              v-model="eventDetail.selectedEvent.parasol"
                              aria-describedby="feedback-parasol"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Table'">
                          <b-col>
                            <!-- Feld: Anzahl Tische -->
                            <b-form-input
                              id="input-table"
                              v-model="eventDetail.selectedEvent.table"
                              aria-describedby="feedback-table"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.Pedestal'">
                          <b-col>
                            <!-- Feld: Anzahl Podeste -->
                            <b-form-input
                              id="input-pedestal"
                              v-model="eventDetail.selectedEvent.pedestal"
                              aria-describedby="feedback-pedestal"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row v-if="material.materialTitleLic === 'Lov.Sat.NwpMaterial.CoolAndCleanBanner'">
                          <b-col>
                            <!-- Feld: Anzahl Cool and Clean Banners -->
                            <b-form-input
                              id="input-coolAndCleanBanner"
                              v-model="eventDetail.selectedEvent.coolAndCleanBanner"
                              aria-describedby="feedback-coolAndCleanBanner"
                              type="number"
                              size="sm"
                              disabled
                            ></b-form-input>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="3">
                        <span v-html="material[`information${$i18n.locale.toUpperCase()}`]"></span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-lieferadresse-material style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_lieferadresse_material') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-lieferadresse-material" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_name') }}:
                  </b-col>
                  <b-col>
                    <span>{{ eventDetail.selectedEvent.shippingFirstName }}</span>
                    <span class="ml-1">{{ eventDetail.selectedEvent.shippingName }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_verein_schule_organisation') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.shippingOrganisation || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_lieferadresse') }}:
                  </b-col>
                  <b-col>
                    <span v-if="eventDetail.selectedEvent.shippingAddress" class="d-block">{{ eventDetail.selectedEvent.shippingAddress }}</span>
                    <span v-if="eventDetail.selectedEvent.shippingAddressLine2" class="d-block">{{ eventDetail.selectedEvent.shippingAddressLine2 }}</span>
                    <span v-if="eventDetail.selectedEvent.shippingZipCode" class="d-inline-block">{{ eventDetail.selectedEvent.shippingZipCode }}</span>
                    <span v-if="eventDetail.selectedEvent.shippingCity" class="d-inline-block">, {{ eventDetail.selectedEvent.shippingCity }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_lieferkanton') }}:
                  </b-col>
                  <b-col>
                    {{ $t(eventDetail.selectedEvent.shippingCanton) || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_telefon_tagsüber_mobile') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.shippingMobile || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_telefon_lieferinformation') }}:
                  </b-col>
                  <b-col>
                    {{ eventDetail.selectedEvent.shippingPhone || '-' }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="font-weight-bold">
                    {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_email_lieferinformation') }}:
                  </b-col>
                  <b-col>
                    <b-link v-if="eventDetail.selectedEvent.shippingEmail" :href="`mailto:${eventDetail.selectedEvent.shippingEmail}`">{{ eventDetail.selectedEvent.shippingEmail }}</b-link>
                    <span v-else>{{ eventDetail.selectedEvent.shippingEmail || '-' }}</span>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-logo style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_logo') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-logo" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row v-if="eventDetail.logo" class="mb-3">
                  <b-col>
                    <b-img :src="eventDetail.logo" fluid :alt="`Logo - ${eventDetail.selectedEvent.nwpName}`" class="border" style="max-height:95px;"></b-img>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col>
                    <b-alert variant="info" show>
                      {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_kein_logo_hochgeladen') }}
                    </b-alert>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col v-if="!eventDetail.logo">
                    <b-button v-b-modal="'modal-upload-logo'" @click="$helpers.scrollToTop();" variant="success" class="mr-2">
                      {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_upload') }}
                    </b-button>
                    <b-modal id="modal-upload-logo" ref="modal-upload-logo" hide-backdrop hide-footer content-class="shadow" :title="$t('event_registrierung.registrierung.label_logoupload')">
                      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
                        <b-form @submit.prevent="handleSubmit(uploadLogo)">
                          <ValidationProvider
                            :name="$t('event_registrierung.registrierung.label_logoupload_name')"
                            rules="required|ext:jpg,jpeg,png,gif,bmp"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              id="fieldset-logo"
                              label-for="input-logo"
                            >
                              <b-form-file
                                id="input-logo"
                                v-model="uploads.logo"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-logo"
                                class="mt-3"
                                plain
                              ></b-form-file>
                              <b-form-invalid-feedback id="feedback-logo">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                          <b-alert v-if="feedback.logoModal.display" :variant="feedback.logoModal.variant" show>
                            {{ feedback.logoModal.message }}
                          </b-alert>
                          <b-button type="submit" variant="success" block>
                            {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_upload') }}
                          </b-button>
                        </b-form>
                      </ValidationObserver>
                    </b-modal>
                  </b-col>
                  <b-col v-if="eventDetail.logo">
                    <b-button v-b-modal="'modal-delete-logo'" @click="$helpers.scrollToTop();" variant="danger">
                      {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_delete') }}
                    </b-button>
                    <b-modal id="modal-delete-logo" ref="modal-delete-logo" hide-backdrop hide-footer content-class="shadow" :title="$t('veranstalter_seite.meine_wettkämpfe_detail.label_upload')">
                      <b-row>
                        <b-col>
                          <p class="mb-0">
                            {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_logo_löschen_alert_1') }}
                          </p>
                          <p>
                            {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_logo_löschen_alert_2') }}
                          </p>
                        </b-col>
                      </b-row>
                      <b-row v-if="feedback.logoModal.display">
                        <b-col>
                          <b-alert :variant="feedback.logoModal.variant" show>
                            {{ feedback.logoModal.message }}
                          </b-alert>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="6" class="mb-2 mb-sm-0">
                          <b-button @click="hideModal('modal-delete-logo')" block>
                          {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_abbrechen') }}</b-button>
                        </b-col>
                        <b-col sm="6">
                          <b-button @click="deleteLogo" variant="danger" block>
                            {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_delete') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-modal>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-pdf-uploads style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_pdf_uploads_für_veranstaltungs_subwebseite') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-pdf-uploads" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row v-if="downloadsTable.items.length > 0">
                  <b-col>
                    <b-table :items="downloadsTable.items" :fields="downloadsTable.fields" thead-class="d-none" borderless small striped>
                      <template #cell(actions)="row">
                        <b-button size="sm" variant="success" class="mr-2" @click="getFileData(row.item)">
                          {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_download') }}
                        </b-button>
                        <b-button :id="`modal-delete-file-${row.item.fileId}`" v-b-modal="`modal-delete-file-${row.item.fileId}`" @click="$helpers.scrollToTop();" size="sm" variant="danger">
                          {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_delete') }}
                        </b-button>
                        <b-modal :id="`modal-delete-file-${row.item.fileId}`" :ref="`modal-delete-file-${row.item.fileId}`" hide-backdrop hide-footer content-class="shadow" :title="$t('veranstalter_seite.meine_wettkämpfe_detail.label_document_löschen')">
                          <b-row>
                            <b-col>
                              <p class="mb-0">
                                {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_document_löschen_alert_1') }}
                              </p>
                              <p>
                                {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_document_löschen_alert_2') }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row v-if="feedback.fileModal.display">
                            <b-col>
                              <b-alert :variant="feedback.fileModal.variant" show>
                                {{ feedback.fileModal.message }}
                              </b-alert>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="6" class="mb-2 mb-sm-0">
                              <b-button @click="hideModal(`modal-delete-file-${row.item.fileId}`)" block>
                              {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_abbrechen') }}</b-button>
                            </b-col>
                            <b-col sm="6">
                              <b-button @click="deleteFile(row.item.fileId)" variant="danger" block>
                                {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_delete') }}
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-modal>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col >
                    <b-alert variant="info" show>
                      {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_keine_dateien_hochgeladen') }}
                    </b-alert>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button v-b-modal.modal-upload-file @click="$helpers.scrollToTop();" variant="success" class="mr-2">
                      {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_upload') }}
                    </b-button>
                    <b-modal id="modal-upload-file" ref="modal-upload-file" hide-backdrop hide-footer content-class="shadow" :title="$t('event_registrierung.registrierung.label_dateiupload')">
                      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
                        <b-form @submit.prevent="handleSubmit(uploadFile)">
                          <ValidationProvider
                            :name="$t('event_registrierung.registrierung.label_dateiupload_name')"
                            rules="required|ext:pdf,xls,xlsx"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              id="fieldset-file"
                              label-for="input-file"
                            >
                              <b-form-file
                                id="input-file"
                                v-model="uploads.file"
                                :state="getValidationState(validationContext)"
                                aria-describedby="feedback-file"
                                class="mt-3"
                                plain
                              ></b-form-file>
                              <b-form-invalid-feedback id="feedback-file">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </ValidationProvider>
                          <b-alert v-if="feedback.fileModal.display" :variant="feedback.fileModal.variant" show>
                            {{ feedback.fileModal.message }}
                          </b-alert>
                          <b-button type="submit" variant="success" block>
                            {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_upload') }}
                          </b-button>
                        </b-form>
                      </ValidationObserver>
                    </b-modal>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

              <b-row v-b-toggle.accordion-downloads style="outline: none;">
                <b-col>
                  <h2 class="h5 mb-0">{{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_downloads') }}</h2>
                </b-col>
              </b-row>

              <b-collapse id="accordion-downloads" class="mt-3" accordion="veranstaltungs-accordion" role="tabpanel">
                <b-row>
                  <b-col>
                    <div class="row mb-3">
                      <div class="col-auto">
                        <b-button @click="downloadInfoDocument" variant="primary" block :disabled="!eventDetail.selectedEvent.applicationConfirmed">
                          {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_infoblatt') }}
                        </b-button>
                      </div>
                      <div class="col d-flex flex-column justify-content-center align-content-center">
                        <div v-if="displaySpinners.downloadInfoDocument" style="height: 1.2rem; width: 1.2rem;">
                          <b-spinner label="Loading..." style="height: 1.2rem; width: 1.2rem;"></b-spinner>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-auto">
                        <b-button variant="primary" block @click="getEventRegisteredKids(eventDetail.selectedEvent.nwpId)" :disabled="eventDetail.selectedEvent.applicationConfirmed === false">
                          {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_anmeldungen_herunterladen') }}
                        </b-button>
                      </div>
                      <div class="col d-flex flex-column justify-content-center align-content-center">
                        <div v-if="displaySpinners.getEventRegisteredKids" style="height: 1.2rem; width: 1.2rem;">
                          <b-spinner label="Loading..." style="height: 1.2rem; width: 1.2rem;"></b-spinner>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="eventDetail.selectedEvent.usedEvaluationSoftware == 'Lov.Sat.UsedEvaluationSoftwareNWP.ExcelSheet'">
                      <div class="col-auto">
                        <b-button @click="downloadResultateDocument" variant="primary" block>
                          {{ $t('veranstalter_seite.meine_wettkämpfe_detail.label_resultate') }}
                        </b-button>
                      </div>
                      <div class="col d-flex flex-column justify-content-center align-content-center">
                        <div v-if="displaySpinners.downloadResultateDocument" style="height: 1.2rem; width: 1.2rem;">
                          <b-spinner label="Loading..." style="height: 1.2rem; width: 1.2rem;"></b-spinner>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      events: [],
      downloadsTable: {
        display: true,
        sortBy: 'fileName',
        sortDesc: true,
        items: [],
        fields: [
          {
            key: "fileName",
            label: "Dateiname",
            sortable: true
          },
          {
            key: "actions",
            label: "",
            sortable: false,
            tdClass: "text-right"
          }
        ]
      },
      table: {
        display: true,
        sortBy: 'startDate',
        sortDesc: true,
        fields: [
          {
            key: "nwpCanton",
            label: this.$t('veranstalter_seite.meine_wettkämpfe.label_kanton'),
            sortable: true,
          },
          {
            key: "startDate",
            label: this.$t('veranstalter_seite.meine_wettkämpfe.label_austragungsdatum'),
            sortable: true,
            formatter: "formatDate",
          },
          {
            key: "nwpName",
            label: this.$t('veranstalter_seite.meine_wettkämpfe.label_wettkampfname'),
            sortable: true,
          },
          {
            key: "venue",
            label: this.$t('veranstalter_seite.meine_wettkämpfe.label_veranstaltungsort'),
            sortable: true,
          },
          {
            key: "nwpCategory",
            label: this.$t('veranstalter_seite.meine_wettkämpfe.label_wettkampfkategorie'),
            sortable: true,
          },
          {
            key: "nwpStatus",
            label: this.$t('veranstalter_seite.meine_wettkämpfe.label_antrag'),
            sortable: true,
          },
          {
            key: "id",
            label: "",
            sortable: false,
          },
        ],
      },
      eventDetail: {
        display: false,
        displayBankAccount: true,
        selectedEvent: null,
        logo: null,
        material: [],
      },
      displaySpinners: {
        downloadInfoDocument: false,
        getEventRegisteredKids: false,
        downloadResultateDocument: false,
      },
      customMaterialSortingOrder: [
        'Lov.Sat.NwpMaterial.Startnumber',
        'Lov.Sat.NwpMaterial.Diploma',
        'Lov.Sat.NwpMaterial.Poster',
        'Lov.Sat.NwpMaterial.Flyer',
        'Lov.Sat.NwpMaterial.Medal',
        'Lov.Sat.NwpMaterial.HelperShirt',
        'Lov.Sat.NwpMaterial.HelperCap',
        'Lov.Sat.NwpMaterial.WinnerPrize',
        'Lov.Sat.NwpMaterial.PodiumPrize',
        'Lov.Sat.NwpMaterial.ParticipantGift',
        'Lov.Sat.NwpMaterial.StartingTent',
        'Lov.Sat.NwpMaterial.Tent',
        'Lov.Sat.NwpMaterial.Banner',
        'Lov.Sat.NwpMaterial.PopOutFlag',
        'Lov.Sat.NwpMaterial.BeachFlag',
        'Lov.Sat.NwpMaterial.Archway',
        'Lov.Sat.NwpMaterial.Parasol',
        'Lov.Sat.NwpMaterial.Table',
        'Lov.Sat.NwpMaterial.Pedestal',
        'Lov.Sat.NwpMaterial.CoolAndCleanBanner',
      ],
      uploads: {
        logo: null,
        file: null,
      },
      feedback: {
        logoModal: {
          display: false,
          variant: '',
          message: '',
        },
        fileModal: {
          display: false,
          variant: '',
          message: '',
        }
      }
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == 'modal-upload-logo' || modalId == 'modal-delete-logo') {
        this.resetModalFeedbackLogo();
      }
      if (modalId.includes('modal-upload-file') || modalId.includes('modal-delete-file')) {
        this.resetModalFeedbackFile();
      }
    })
  },
  created() {
    this.getEvents();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    mapEvents(data) {
      var tmp = [];
      data.forEach((event) => {
        let tmpEvent = event;

        tmpEvent.nwpType = this.$t(event.nwpType);
        tmpEvent.nwpCanton = this.$t(event.nwpCanton);
        tmpEvent.nwpCategoryKey = event.nwpCategory;
        tmpEvent.nwpCategory = this.$t(event.nwpCategory);
        tmpEvent.nwpEligibleKey = event.nwpEligible;
        tmpEvent.nwpEligible = this.$t(event.nwpEligible);
        tmpEvent.medalsInterestLicKey = event.medalsInterestLic;
        tmpEvent.medalsInterestLic = this.$t(event.medalsInterestLic);
        tmpEvent.contactCanton = this.$t(event.contactCanton);
        tmpEvent.correspondenceLanguage = this.$t(event.correspondenceLanguage);

        tmp.push(tmpEvent);
      });
      return tmp;
    },
    getEvents() {
      // Definiere nwpCategories für die Anzeige der Events
      const data = {
        nwpCategories: "Lov.Sat.NWPCategory.SprintCantonFinal;Lov.Sat.NWPCategory.SprintSwissFinal;Lov.Sat.NWPCategory.SprintLocalContest",
      };

      this.$store
        .dispatch("veranstalter/getEvents", data)
        .then((resp) => {
          var response = resp.data;
          if (response.nwpDatas && response.nwpDatas.length > 0) {
            var pendingEvents = this.mapEvents(response.nwpDatas);
            pendingEvents = pendingEvents.map((event) => {
              event.applicationConfirmed = false;
              return event;
            })
            this.events = pendingEvents
          }
        })
        .catch((err) => {
          // Do something
        })
        .then(() => {
          this.$store
            .dispatch("veranstalter/getConfirmedEvents", data)
            .then((resp) => {
              var response = resp.data;
              if (response.nwpDatas && response.nwpDatas.length > 0) {
                var confirmedEvents = this.mapEvents(response.nwpDatas);
                confirmedEvents = confirmedEvents.map((event) => {
                  event.applicationConfirmed = true;
                  return event;
                })
                if (confirmedEvents.length > 0) {
                  for (let i = 0; i < confirmedEvents.length; i++) {
                    // Prüfe, ob für den bestätigten Wettkampf ein Gegenstück existiert (wird anschliessend ersetzt)
                    const index = this.events.findIndex(event => event.nwpId === confirmedEvents[i].nwpLinkId);

                    // Ersetze die Wettkampfanträge in der Liste mit den bestätigten Wettkämpfen
                    if (index !== -1) {
                      this.events[index] = confirmedEvents[i];
                    }              
                  }
                }
              }
            })
            .catch((err) => {
              // Do something
            })
            .then(() => {
              if (this.table.display && this.events.length > 0) this.refreshEventsTable();
              if (this.eventDetail.selectedEvent) {
                const nwpId = this.eventDetail.selectedEvent.nwpId;
                for (let index = 0; index < this.events.length; index++) {
                  if (nwpId == this.events[index].nwpId) {
                    this.displayEventDetails(this.events[index]);
                  }              
                }
              }
            });
        });
    },
    getNWPMaterials(value) {
      var data = {
        sprintLA: value === 'Lov.Sat.NWPCategory.SprintLocalContest' ? true : false, // Visanasprint: Lokale Ausscheidung
        sprintKF: value === 'Lov.Sat.NWPCategory.SprintCantonFinal' ? true : false, // Visanasprint: Kantonalfinal
        sprintSF: value === 'Lov.Sat.NWPCategory.SprintSwissFinal' ? true : false, // Visanasprint: Schweizer Final
        milleLA: value === 'Lov.Sat.NWPCategory.MilleLocalContest' ? true : false, // Mille Gruyère: Lokale Ausscheidung
        milleRF: value === 'Lov.Sat.NWPCategory.MilleCantonFinal' ? true : false, // Mille Gruyère: Kantonalfinal
        milleSF: value === 'Lov.Sat.NWPCategory.MilleSwissFinal' ? true : false, // Mille Gruyère: Schweizer Final
      }

      this.$store
        .dispatch("wettkampf/getWettkampfMaterial", data)
        .then((resp) => {
          this.eventDetail.material = resp.data;
          this.eventDetail.material = this.eventDetail.material.filter((material) => {
            return ((data.sprintLA && material.sprintLA) || (data.sprintKF && material.sprintKF) || (data.sprintSF && material.sprintSF) || (data.milleLA && material.milleLA) || (data.milleRF && material.milleRF) || (data.milleSF && material.milleSF))
          })
          this.eventDetail.material.map((material) => {
            if (material.image) {
              const fileExt = material.image.fileName.split('.').pop();
              material.image.src = `data:image/${fileExt};base64,${material.image.fileContent}`
            }
            
            material.descriptionDE = material.descriptionDE.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.descriptionFR = material.descriptionFR.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.descriptionIT = material.descriptionIT.replace(/(?:\r\n|\r|\n)/g, '<br>')
            
            material.informationDE = material.informationDE.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.informationFR = material.informationFR.replace(/(?:\r\n|\r|\n)/g, '<br>')
            material.informationIT = material.informationIT.replace(/(?:\r\n|\r|\n)/g, '<br>')

            // Sortiere Materialien anhand der manuell definierten Sortierreihenfolge.
            // Wenn das Material in der bestimmten Sortierung nicht gefunden werden konnte, setze als Order-Parameter 999999.
            // So wird das betroffene Material ganz hinten am Array angehängt.
            const orderParam = this.customMaterialSortingOrder.indexOf(material.materialTitleLic)
            material.order = orderParam !== -1 ? orderParam : 999999;
          })

          // Sortiere Material anhand des Order-Parameters
          this.eventDetail.material.sort((a, b) => a.order - b.order);
        })
        .catch((err) => {
          console.log('getNWPMaterials (error)', err);
        });

    },
    getFileData(file) {
      this.$store
        .dispatch("dateien/getDocument", file)
        .then((resp) => {
          this.downloadFile(resp, file.fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEventLogo(file) {
      this.$store
        .dispatch("dateien/getDocument", file)
        .then((resp) => {
          this.eventDetail.logo = `data:image/${this.eventDetail.selectedEvent.logoMetaData.fileType};base64,${resp}`;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEventRegisteredKids(nwpId) {
      // Loader anzeigen
      this.displaySpinners.getEventRegisteredKids = true;
      
      this.$store
        .dispatch("veranstalter/getEventRegisteredKids", {nwpId: nwpId})
        .then((resp) => {
          this.downloadFile(resp, 'anmeldungen.xlsx');
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          // Loader ausblenden
          this.displaySpinners.getEventRegisteredKids = false;
        });
    },
    deleteLogo() {
      if (this.eventDetail.selectedEvent.applicationConfirmed) {
        this.$store
          .dispatch("dateien/deleteLogoForNWP", this.eventDetail.selectedEvent.logoMetaData.fileId)
          .then((resp) => {
            this.eventDetail.selectedEvent.logoMetaData = null;
            this.$refs['modal-delete-logo'].hide();
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.logoModal.display = true;
            } else {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_logo_delete');
              this.feedback.logoModal.display = true;
            }
          })
          .then(() => {
            this.getEvents()
          });
      } else {
        this.$store
          .dispatch("dateien/deleteLogoForNWPApplication", this.eventDetail.selectedEvent.logoMetaData.fileId)
          .then((resp) => {
            this.eventDetail.selectedEvent.logoMetaData = null;
            this.$refs['modal-delete-logo'].hide();
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.logoModal.display = true;
            } else {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_logo_delete');
              this.feedback.logoModal.display = true;
            }
          })
          .then(() => {
            this.getEvents()
          });
      }
    },
    deleteFile(fileId) {
      if (this.eventDetail.selectedEvent.applicationConfirmed) {
        this.$store
          .dispatch("dateien/deleteFileForNWP", fileId)
          .then((resp) => {
            this.$refs[`modal-delete-file-${fileId}`].hide();
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.fileModal.display = true;
            } else {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_document_delete');
              this.feedback.fileModal.display = true;
            }
          })
          .then(() => {
            this.getEvents()
          });
      } else {
        this.$store
          .dispatch("dateien/deleteFileForNWPApplication", fileId)
          .then((resp) => {
            this.$refs[`modal-delete-file-${fileId}`].hide();
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.fileModal.display = true;
            } else {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_document_delete');
              this.feedback.fileModal.display = true;
            }
          })
          .then(() => {
            this.getEvents()
          });
      }
    },
    async uploadLogo() {
      const nwpId = this.eventDetail.selectedEvent.nwpId;
      const logo = await this.file2bin(this.uploads.logo);

      if (this.eventDetail.selectedEvent.applicationConfirmed) {
        const data = {
          nwpId: nwpId,
          file: logo,
        }

        // Sende Logo daten an api
        this.$store
          .dispatch("dateien/uploadLogoForNWP", data)
          .then((resp) => {
            this.$refs['modal-upload-logo'].hide();
            this.uploads.logo = null;
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.logoModal.display = true;
            } else {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_logo_upload');
              this.feedback.logoModal.display = true;
            }
          })
          .then(() => {
            this.getEvents();
          });
        } else {
          const data = {
            nwpId: nwpId,
            file: logo,
          }
          
          this.$store
          .dispatch("dateien/uploadLogoForNWPApplication", data)
          .then((resp) => {
            this.$refs['modal-upload-logo'].hide();
            this.uploads.logo = null;
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.logoModal.display = true;
            } else {
              this.feedback.logoModal.variant = 'danger';
              this.feedback.logoModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_logo_upload');
              this.feedback.logoModal.display = true;
            }
          })
          .then(() => {
            this.getEvents();
          });
      }

    },
    async uploadFile() {
      const nwpId = this.eventDetail.selectedEvent.nwpId;
      const file = await this.file2bin(this.uploads.file);

      if (this.eventDetail.selectedEvent.applicationConfirmed) {
        const data = {
          nwpId: nwpId,
          file: file,
        }

        // Sende Datei daten an api
        this.$store
          .dispatch("dateien/uploadFileForNWP", data)
          .then((resp) => {
            this.$refs['modal-upload-file'].hide();
            this.uploads.logo = null;
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.fileModal.display = true;
            } else {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_document_upload');
              this.feedback.fileModal.display = true;
            }
          })
          .then(() => {
            this.getEvents();
          });
        } else {
          const data = {
            nwpId: nwpId,
            file: file,
          }
          
          this.$store
          .dispatch("dateien/uploadFileForNWPApplication", data)
          .then((resp) => {
            this.$refs['modal-upload-file'].hide();
            this.uploads.logo = null;
          })
          .catch((err) => {
            if (403 === err.response.status) {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_no_permission');
              this.feedback.fileModal.display = true;
            } else {
              this.feedback.fileModal.variant = 'danger';
              this.feedback.fileModal.message = this.$t('veranstalter_seite.meine_wettkämpfe_detail.message_error_on_document_upload');
              this.feedback.fileModal.display = true;
            }
          })
          .then(() => {
            this.getEvents();
          });
      }

    },
    hideModal(id) {
      this.$refs[id].hide();
    },
    downloadInfoDocument() {
      // Loader anzeigen
      this.displaySpinners.downloadInfoDocument = true;

      var data = {
        eventId: this.eventDetail.selectedEvent.nwpId,
        languageCode: this.$i18n.locale.toString().toUpperCase()
      };
      this.$store
        .dispatch("dateien/getEventInfoDoc", data)
        .then((resp) => {
          this.downloadFile(resp, "Infoblatt.pdf");
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          // Loader ausblenden
          this.displaySpinners.downloadInfoDocument = false;
        });
    },
    downloadResultateDocument() {
      // Loader anzeigen
      this.displaySpinners.downloadResultateDocument = true;

      const data = {
        nwpId: this.eventDetail.selectedEvent.nwpId,
        languageCode: 'de'
      };

      this.$store
        .dispatch("veranstalter/getOrganizerSprintEventExcel", data)
        .then((resp) => {
          this.downloadFile(resp, "Auswertung.xlsm");
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          // Loader ausblenden
          this.displaySpinners.downloadResultateDocument = false;
        });
    },
    downloadFile(file, name) {
      const link = document.createElement("a");
      link.href = "data:application/octet-stream;base64," + file;
      link.setAttribute("download", name);
      link.click();
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString("de", options);
    },
    formatTime(ms) {
      let date = new Date()
      date.setTime(ms);
      const hours = date.getUTCHours().toString().padStart(2,0);
      const minutes = date.getUTCMinutes().toString().padStart(2,0);
      return `${hours}:${minutes} Uhr`;
    },
    file2bin(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          var fileObj = {
            fileName: file.name,
            fileContent: reader.result.split(",")[1],
          };
          resolve(fileObj);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsDataURL(file);
      });
    },
    displayEventDetails(event) {
      this.eventDetail.selectedEvent = event;
      this.downloadsTable.items = this.eventDetail.selectedEvent.filesMetadata;
      if (this.eventDetail.selectedEvent.logoMetaData) {
        this.getEventLogo(this.eventDetail.selectedEvent.logoMetaData);
      } else {
        this.eventDetail.logo = null;
      }
      this.getNWPMaterials(this.eventDetail.selectedEvent.nwpCategoryKey);
      this.$emit('displayBackBtn');
      this.eventDetail.displayBankAccount = this.eventDetail.selectedEvent.nwpCategoryKey !== 'Lov.Sat.NWPCategory.SprintLocalContest';
      this.eventDetail.display = true;
      this.table.display = false;
    },
    displayTable() {
      this.eventDetail.display = false;
      this.table.display = true;
      this.eventDetail.selectedEvent = null;
      this.$emit('hideBackBtn');
    },
    resetModalFeedbackLogo() {
      this.feedback.logoModal.display = false;
      this.feedback.logoModal.variant = '';
      this.feedback.logoModal.message = '';
    },
    resetModalFeedbackFile() {
      this.feedback.fileModal.display = false;
      this.feedback.fileModal.variant = '';
      this.feedback.fileModal.message = '';
    },
    refreshEventsTable() {
      this.$refs.eventsTable.refresh();
    }
  }
};
</script>

<style lang="scss" scoped>
  #accordion-wrapper {
    .row {
      h2 {
        &:after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          transition: transform .1s ease-in-out;
        }
      }
      &.not-collapsed {
        h2 {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  #accordion-downloads {
    button {
      width: 280px;
    }
  }
</style>